import _ from 'lodash';
import moment from 'moment';
import { userStatusMeta, userStatuses } from './constants';

export const sortFields = (a, b, fieldsOrder) => {
  const orderA =
    fieldsOrder.indexOf(a.name) === -1 ? Infinity : fieldsOrder.indexOf(a.name);
  const orderB =
    fieldsOrder.indexOf(b.name) === -1 ? Infinity : fieldsOrder.indexOf(b.name);

  return orderA - orderB;
};

export const isValidJSON = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
};

export const parseJSON = (str) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return null;
  }
};

export const extractWithVal = (payload) => {
  const funcsThatCheckForInvalidValues = [
    _.isNil,
    _.flowRight(_.isEmpty, _.toString),
  ];
  return _.omitBy(payload, (val) =>
    funcsThatCheckForInvalidValues.some((func) => func(val)),
  );
};

export const formatSelectorData = (dataList, { value: key, label }) =>
  _.map(dataList, (item) => {
    const k = item[key];
    const value = item[label];
    return { ...item, value: `${k}`, label: value };
  });

export const excludeFromSelectorData = (dataList, ids = []) => {
  const formattedIds = _.map(_.filter(ids, Boolean), String);
  return _.filter(dataList, ({ value }) => !formattedIds.includes(value));
};

export const formatSelectorDataObj = (obj, inverse = false) =>
  _.keys(obj).map((key) => {
    const value = obj[key];
    return inverse
      ? { value: `${value}`, label: key }
      : { value: `${key}`, label: value };
  });

export const getWeekday = (date = moment()) => date.format('dddd');

export const getUserFullName = (obj) =>
  obj?.first_name || obj?.last_name
    ? `${obj?.first_name} ${obj?.last_name ?? ''}`
    : '';

export const getCurrentReasonType = (reasonTypes, type) =>
  reasonTypes
    ? _.keys(reasonTypes).find((key) => reasonTypes[key] === type)
    : '';

export const formatUserFilterPayload = ({ status, search, page, ...rest }) => {
  const payload = {
    'filter[search]': search,
    'filter[byStatusType]': status,
    ...rest,
    page:
      search || (status && status !== userStatusMeta[userStatuses.all])
        ? ''
        : page,
  };

  return extractWithVal(payload);
};

export const getFileExt = (filename) => filename.split('.').pop();

export const formatDate = (date, format) =>
  date ? moment(date).format(format ?? 'YYYY-MM-DD') : '';

export const getFileIdFromPath = (path) =>
  path ? _.last(path?.split('/')) : '';

export const getDiffBetweenTwoNumbers = (num1, num2) => {
  const diff = (Math.abs(num1 - num2) / ((num1 + num2) / 2)) * 100;

  return diff.toFixed(2);
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const applyPromiseResolverForOldBrowser = () => {
  // works for Safari
  if (typeof Promise.withResolvers === 'undefined') {
    if (window)
      window.Promise.withResolvers = () => {
        let resolve;
        let reject;
        const promise = new Promise((res, rej) => {
          resolve = res;
          reject = rej;
        });
        return { promise, resolve, reject };
      };
  }
};

export const isWeekend = (date) => {
  const day = date.getDay();
  if (day === 0 || day === 6) return true;
  return false;
};

export const addWorkDays = (date, workDays) => {
  const momentDate = moment(date, 'YYYY-MM-DD');

  for (let index = 0; index < workDays; ) {
    momentDate.add(1, 'days');
    if (!isWeekend(momentDate.toDate())) index += 1;
  }

  return momentDate;
};

export const getFileIdFromUrl = (url) => {
  if (!url || typeof url !== 'string') return null;

  return _.last(_.split(url, '/'));
};

export const formatUploadFile = (file) => ({
  file,
  id: _.uniqueId(),
  objectUrl: URL.createObjectURL(file),
});

export const formatNumberToLocale = (number) => {
  const formattedNumber = _.toNumber(number);
  if (!_.isNumber(formattedNumber) || !_.isFinite(formattedNumber)) return '';

  const nf = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return nf.format(formattedNumber);
};

export const getPercentageOf = (num1, num2) => {
  if (num1 === 0) return null;
  if (!_.isNil(num1) && !_.isNil(num2)) {
    return (num2 * 100) / num1;
  }

  return null;
};

export const parseTimeTrackFormat = (val, inMinutesFormat = false) => {
  const match =
    val.match(/^(\d+(?:\.\d+)?)h(?: (\d+)m)?$/) || val.match(/^(\d+)m$/);
  if (match) {
    const hours = match[1] && val.includes('h') ? parseFloat(match[1]) : 0;
    let minutes = 0;

    if (match[2]) minutes = parseInt(match[2], 10);
    else if (val.includes('m')) minutes = parseInt(match[1], 10);

    if (inMinutesFormat) {
      return hours * 60 + minutes;
    }

    return {
      hours,
      minutes,
    };
  }

  return null;
};

export const minutesToHours = (minutes) =>
  parseFloat(((minutes ?? 0) / 60).toFixed(2));

export const minutesToTimeSheetFormat = (minutes) => {
  if (!minutes) return '';

  if (minutes < 60) return `${minutes}m`;

  const hours = Math.floor(minutes / 60);

  const restMinutes = parseFloat((minutes - hours * 60 || 0).toFixed(2));

  if (hours && !restMinutes) return `${hours}h`;

  return `${hours}h ${restMinutes}m`;
};
